import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../images/centreImage.png";
import "./Navbar.css";
import { Link } from "react-router-dom";

function NavigationBar() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      className="Navigation"
      style={{ backgroundColor: "#81b55e" }}
    >
      <Container>
        <Navbar.Brand
          as={Link}
          to="/"
          style={{ fontSize: "24px", fontWeight: "bold" }}
        >
          <img src={logo} alt="logo" className="logo" />
          Digital Black Religion
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              as={Link}
              to="/Survey"
              style={{ fontSize: "20px", margin: "5px", color: "white" }}
            >
              Connected Faith Survey
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/Twitter"
              style={{ fontSize: "20px", margin: "5px", color: "white" }}
            >
              Timeline of Black Twitter Faith
            </Nav.Link>
            <NavDropdown
              title={
                <span style={{ fontSize: "20px", color: "white" }}>
                  Syllabus
                </span>
              }
              id="collasible-nav-dropdown"
              style={{ fontSize: "20px", margin: "5px", color: "white" }}
            >
              <NavDropdown.Item
                as={Link}
                to="/Authors"
                style={{ fontSize: "20px" }}
              >
                The Research Hub Page
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/Bibliography"
                style={{ fontSize: "20px" }}
              >
                Bibliography & Resources
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="ml-auto">
            <Nav.Link
              as={Link}
              to="/About"
              style={{ fontSize: "20px", margin: "5px", color: "white" }}
            >
              About
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
