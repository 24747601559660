import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Homepage from "./pages/Homepage/Homepage";
import SurveyPage from "./pages/Survey/Survey";
import TwitterPage from "./pages/Twitter/twitter";
import Authors from "./pages/Syllabus/authors";
import Bibliography from "./pages/Syllabus/bibliography";
import About from "./pages/About/About";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useLocation } from "react-router-dom";

function RoutesWithTransition() {
  let location = useLocation();
  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Routes location={location}>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/Survey" element={<SurveyPage />} />
          <Route path="/Twitter" element={<TwitterPage />} />
          <Route path="/Authors" element={<Authors />} />
          <Route path="/Bibliography" element={<Bibliography />} />
          <Route path="/About" element={<About />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

function App() {
  return (
    <div style={{ backgroundColor: "#faf6f0" }}>
      <BrowserRouter>
        <Navbar />
        <RoutesWithTransition />
      </BrowserRouter>
    </div>
  );
}

export default App;
