import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "./bib.css";

function DigitalReligion() {
  Modal.setAppElement("#root");

  const navigate = useNavigate();

  return (
    <div className="bibContainer">
        <div id="intro">
        <h4>
        This bibliography and list of resources was compiled by Erika Gault with much help from research
        assistants Keyania Campbell and Gertus Van Der Vvyer as part of the ongoing Digital Black Religion
        Project at the University of Arizona Center for Digital Humanities. It is ever expanding.
        </h4>
        </div>
        <br></br>

        <h2 class="section">Digital Black Religion</h2>
        <div class="references">
            <div class="citation">
            Moxley Rouse, Carolyn, John L. Jackson, Marla F. Frederick. 2016. Televised Redemption: Black Religious Media and Racial Empowerment. NYU Press.
            <br/><br/>
            </div>
            <div class="desc">
            This book mainly deals with the topic of how Black Christians, Muslims, and Jews have used media to prove their equality, not only in the eyes of God, but also in society. 
            </div>
            <hr></hr>

            <div class="citation">
            Boutros, Alexandra. 2015. "Religion in the Afrosphere: the Constitution of a Blogging Counterpublic." Journal of Communication Inquiry 39, no. 4: 319-337.
            <br/><br/>
            </div>
            <div class="desc">
            This article looks at digital representation of the Black Diaspora in blogs and argues that these instances of online visibility are valuable to the digital counterpublic discourse.
            </div>
            <hr></hr>

            <div class="citation">
            Butler, Phillip. 2019. Black Transhuman Liberation Theology: Technology and Spirituality. NY: Bloomsbury. 
            <br/><br/>
            </div>
            <div class="desc">
            Mediating Black religious studies, spirituality studies, and liberation theology, Philip Butler explores what might happen if Black people in the United States merged technology and spirituality in their fight towards materializing liberating realities.
            </div>
            <hr></hr>

            <div class="citation">
            Helland, Christopher. 2007. "Diaspora on the Electronic Frontier: Developing Virtual Connections with Sacred Homelands." Journal of Computer-Mediated Communication 12, no. 3: 956-976.
            <br/><br/>
            </div>
            <div class="desc">
            This study demonstrates how diaspora religious traditions utilized the Internet to develop significant network connections among each other and also to their place of origins.
            </div>
            <hr></hr>

            <div class="citation">
            Jackson Jr., John L. 2012. “ETHNOGRAPHY IS, ETHNOGRAPHY AIN’T.” Cultural Anthropology, no. 27: 480-497. <a href="https://doi.org/10.1111/j.1548-1360.2012.01155.X">https://doi.org/10.1111/j.1548-1360.2012.01155.X</a>
            <br/><br/>
            </div>
            <div class="desc">
            This article argues that ethnography needs to be redefined when digitality is taken seriously in a “post-Writing Culture moment” as sociality changes with technology and ethnography grows with it. 
            </div>
            <hr></hr>

            <div class="citation">
            Clay, Elonda. 2004. “Subtle Impact: Technology Trends and the Black Church.” Journal of the Interdenominational Theological Center no. 31: 152-178.
            <br/><br/>
            </div>
            <div class="desc">
            This essay discusses the impact of technological trends on the Black Church and calls for a shared responsibility with Christians and private organizations that provide the technology.
            </div>
            <hr></hr>
            
            <div class="citation">
            Clay, Elonda. 2011. “Media, Spiritualities and Social Change.” In Mediated Science, Genetics and Identity in the US African Diaspora, edited by Stewart M. Hoover and Monica Emerich, 25-35. New York City: Continuum.
            <br/><br/>
            </div>
            <div class="desc">
            This book brings together perspectives from all over the world and different disciplines regarding the topic of spiritualized social change and how ‘spiritualities’ are expressed through media.
            </div>
            <hr></hr>

            <div class="citation">
            Clay, Elonda. 2011. “These Gods Got Swagger: Avatars, Gameplay, and the Digital Performance of Hip Hop Culture in Machinima.” Bulletin for the Study of Religion, no. 40: 4-9.
            <br/><br/>
            </div>
            <div class="desc">
            This article discusses the implication of game engines and movie-making software on African American religions by focusing on the aesthetic of ‘swagger’ in these contexts.
            </div>
            <hr></hr>

            <div class="citation">
            Clay, Elonda. 2015. “ #NOWTHATSRELIGIONANDHIPHOP: Mapping the Terrain of Religion and Hip Hop in Cyberspace.” In Religion in Hip Hop: Mapping the New Terrain in the US, edited by Miller, Monica R., Anthony B. Pinn, and Bernard ‘Bun B' Freeman, 87-95. New York City: Bloomsbury Publishing.
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Gault, Erika. 2022. Networking the Black Church: Digital Black Christians and Hip Hop. Forthcoming.
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Gault, Erika. 2018. “Performing to the Rhythm of Justice: Martin Luther King’s Speeches and the 
            Urban-Digital Context.” In Urban Ministry Reconsidered: Contexts and Approaches, edited by R. Drew Smith, Stephanie Boddie, and Ronald Peters, 247–53. Louisville, KY: Westminster John Knox Press.
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Gault, Erika. “Why Are All the Gospel Rappers Online? The Role of Social Media Sites 
            in Building Communities.” In Revisiting Subcultures: From Art of Resistance to Creative Innovation, edited by Steven Malliet, Alexander Dhoest, Jacques Haers, and Barbara Seagaert, 87-106, New York: Routledge.
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Gault, Erika, Guest Editor, Special Issue: Africana Digital Humanities, Fire!!!, “My People Are 
            Free: Theorizing the Digital Black Church” (Spring 2020).
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Gault, Erika. 2018. “When Saints Found Out: Tasha Cobbs, Nicki Minaj, and the Policing of 
            Black Christianity Online.” Fire!!! The Multimedia Studies Journal of Black Studies, no. 1: 9–34.
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Kiamu, Boye Nelson, and Bala A. Musa. 2021. "Theorizing the Communication of Digital Religion as Popular Culture in Africa: The Case for Alternative Epistemological Models." Howard Journal of Communications 32, no. 2: 139-155.
            <br/><br/>
            </div>
            <div class="desc">
            This article looks at digital religion in Africa as a mode of spiritual practice and popular culture, or entertainment, as an effort to create a mainstream culture.
            </div>
            <hr></hr>

            <div class="citation">
            Kiamu, Boye Nelson, and Bala A. Musa. 2021. "Theorizing the Communication of Digital Religion as Popular Culture in Africa: The Case for Alternative Epistemological Models." Howard Journal of Communications 32, no. 2: 139-155.
            <br/><br/>
            </div>
            <div class="desc">
            This article looks at digital religion in Africa as a mode of spiritual practice and popular culture, or entertainment, as an effort to create a mainstream culture.
            </div>
            <hr></hr>

            <div class="citation">
            Moultrie, Monique. 2017. Passionate and Pious: Religious Media and Black Women’s Sexuality. Durham, NC: Duke University Press. 
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Sampson, Melva L. 2020. "Digital Hush Harbors." Fire!!! 6, no. 1: 45-66.
            <br/><br/>
            </div>
            <div class="desc">
            This article challenges the reasons church environments are harmful to some members of the community and explores subversions, goals, and theories surrounding the spiritualities of the excluded. 
            </div>
            <hr class="final"></hr>
        </div>

        <h2 class="section">Digital Religion</h2>
        <div class="references">
            <div class="citation">
            Babin, Pierre, and Angela Ann. Zukowski. 2002.  The Gospel in Cyberspace. Nurturing Faith in the Internet Age. Chicago: Loyola Press.
            <br/><br/>
            </div>
            <div class="desc">
            This book maps the impact of Cyberspace on the Gospel by looking at how faith is maintained in the age of the internet.
            </div>
            <hr></hr>

            <div class="citation">
            Campbell, Heidi and Ruth Tsuria. 2012. Digital Religion: Understanding Religious Practice in New Media Worlds. New York City: Routledge.
            <br/><br/>
            </div>
            <div class="desc">
            This book focuses on the five major world religions and approaches the topic from various perspectives such as - cell phones, video games and blogs. This book also deals with the topic of digital religion in a critical sense as it also considers the theoretical, ethical and theological implications of new media on religions. 
            </div>
            <hr></hr>

            <div class="citation">
            Dawson, Lorne L. and Douglas E. Cowan. 2004. Religion Online: Finding Faith on the Internet. New York City: Routledge.
            <br/><br/>
            </div>
            <div class="desc">
            This book serves as an accessible introduction to the impact of the internet on religious topics ranging from the impact on the youth, the founding of new religious movements and also online recruitment.
            </div>
            <hr></hr>

            <div class="citation">
            Moberg, Marcus and Sofia Sjö. 2020. Digital Media, Young Adults and Religion: An International Perspective. New York City: Routledge.
            <br/><br/>
            </div>
            <div class="desc">
            This book deals with the growing impact of social media on young adults in seven different countries and explores how young people use social media in religiously significant ways. The book has several case studies to illustrate how social media is used differently amongst young people across the world.
            </div>
            <hr></hr>

            <div class="citation">
            Spadaro, Antonio. 2014. Cybertheology: Thinking Christianity in the Era of the Internet. New York City: Fordham University Press.
            <br/><br/>
            </div>
            <div class="desc">
            This book addresses the influence of the internet on Christianity from the Catholic perspective and looks at the processes of how theology changes with its interaction with the internet.
            </div>
            <hr></hr>

            <div class="citation">
            Wagner, Rachel. 2012.  Godwired: Religion, Ritual and Virtual Reality. New York City:
            Routledge.
            <br/><br/>
            </div>
            <div class="desc">
            This book explores what religious practice in the digital age looks like and looks at a variety of virtual activities that contribute to meaning making in our religious practices.            
            </div>
            <hr></hr>
            
            <div class="citation">
            Wise, Justin. 2014. The Social Church: A Theology of Digital Communication. Chicago, IL: Moody Publishers.           
            <br/><br/>
            </div>
            <div class="desc">
            Wise explores worship in Christian churches and how they can be more inclusive by embracing social media to not only bring in worshippers, but to engage them outside of the church environment.            
            </div>
            <hr></hr>

            <div class="citation">
            Armfield Greg. G., and R. Lance Holbert. 2003. The Relationship Between Religiosity and Internet Use. Journal of Media and Religion no. 2: 129–144.            
            <br/><br/>
            </div>
            <div class="desc">
            The authors investigate whether religiosity can be a predictor of internet activity and find that the two values negatively correlate with each other. Ramifications of their findings are discussed and areas for future research are also mentioned.             
            </div>
            <hr></hr>

            <div class="citation">
            Bosch, Miriam Diez, Sanz Josep Lluís Micó Sanz, and Alba Sabaté Gauxachs. 2017.“Typing My Religion: Digital Use of Religious Webs and Apps by Adolescents and Youth for Religious and Interreligious Dialogue.” Church, Communication and Culture, 2 no. 2: 121–143.              
            <br/><br/>
            </div>
            <div class="desc">
            This article focuses on Catalan teenagers’ usage of technology in their religious practice. The context might not fit the topic of black religion, but I think that a similar type of study in an American context could yield interesting results.
            </div>
            <hr></hr>

            <div class="citation">
            Campbell, Heidi and Alessandra Vitullo. 2016. “Assessing Changes in the Study of Religious Communities in Digital Religion Studies.” Church, Communication and Culture, 1 no. 1: 73-89.            
            <br/><br/>
            </div>
            <div class="desc">
            This article focuses on research studies within the field of Digital religion and what the approach was when looking at the religious communities in question.
            </div>
            <hr></hr>

            <div class="citation">
            Cheong, Pauline H. 2015. Tweet the Message? Religious Authority and Social Media Innovation. The Journal of Religion, Media and Digital Culture 3 no. 3: 1–19. 
            <br/><br/>
            </div>
            <div class="desc">
            This article looks at the influence of ‘tweets’ on religion and how they contribute to the reconstruction of religious authority and the perception of sacred texts.
            </div>
            <hr></hr>

            <div class="citation">
            Coleman, Monica A.  2015. “Religious Feminists and the Digital Divide.” Journal of Feminist Studies in Religion 31 no. 2: 144-149.
            <br/><br/>
            </div>
            <div class="desc">
            This article addresses the concept of the digital divide from a black feminist perspective and explains that in the digital world, there are many barriers that still exclude a plethora of voices in online religious communities. The author emphasizes that feminist religious scholars should be trained in the use of digital technology to become creators of digital media.
            </div>
            <hr></hr>

            <div class="citation">
            Golan, Oren and Nurit Stadler. 2016. “Building the Sacred Community Online: The Dual Use of the Internet by Chabad.” Media, Culture & Society, 38 no 1: 71–88. <a href="https://doi.org/10.1177/0163443715615415">https://doi.org/10.1177/0163443715615415</a>
            <br/><br/>
            </div>
            <div class="desc">
            This article deals with a Jewish ultra-Orthodox movement and how the sacred is constructed in an online setting by looking at how the internet can be beneficial or negatively impact an online religious community.
            </div>
            <hr></hr>

            <div class="citation">
            Illman, Ruth and Sofia Sjö. 2015. “Facebook as a Site for Inter-religious Encounters: A Case Study from Finland.” Journal of Contemporary Religion 30 no. 3: 383–398. <a href="https://doi.org/10.1080/13537903.2015.1081341">https://doi.org/10.1080/13537903.2015.1081341</a>
            <br/><br/>
            </div>
            <div class="desc">
            The article investigates the strengths and weaknesses of social media in its ability to bring groups of differing religions together or to divide them. 
            </div>
            <hr></hr>

            <div class="citation">
            Mahan, Jeffrey. 2020. Congregation(s) in Digital Culture. Journal of Religion, Media and Digital Culture 9 no. 2: ​​247–264. <a href="https://doi.org/10.1163/21659214-BJA10022">https://doi.org/10.1163/21659214-BJA10022</a>
            <br/><br/>
            </div>
            <div class="desc">
            This essay addresses the question of how digital culture is influencing religious identity, community and leadership in cultures that make use of digital communication.            
            </div>
            <hr></hr>

            <div class="citation">
            Witteborn, Saskia. 2019. “Digital Diaspora: Social Alliances Beyond the Ethnonational Bond.” In The Handbook of Diasporas, Media and Culture, edited by Jéssica Retis and Roza Tsagarousianou, 179-192. 
            Hoboken, NJ: John Wiley & Sons. <a href=" https://doi.org/10.1002/9781119236771.ch12"> https://doi.org/10.1002/9781119236771.ch12</a>
            <br/><br/>
            </div>
            <div class="desc">
            This article focuses on how digital technology has developed along with the diaspora and what can be gained from the interactions between the two developments.            
            </div>
            <hr class="final"></hr>
        </div>

        <h2 class="section">Digital Black Studies</h2>
        <div class="references">
            <div class="citation">
            Brock, Andre. 2020. Distributed Blackness: African American Cybercultures. New York City: NYU Press.
            <br/><br/>
            </div>
            <div class="desc">
            This work places Blackness at the forefront of internet culture and examines the performative aspects of African American Identity in a variety of platforms and practices.
            </div>
            <hr></hr>

            <div class="citation">
            Fouche, Rayvon. 2006. “Say It Loud, I’m Black and I’m Proud: African Americans, American Artifactual Culture, and Black Vernacular Technological Creativity.” American Quarterly 58: 639-661. <a href="https://www.jstor.org/stable/40068387">https://www.jstor.org/stable/40068387</a>
            <br/><br/>
            </div>
            <div class="desc">
            This article discusses how African Americans should reevaluate their relationship with technology and critically engage with how Blackness is represented through technology.
            </div>
            <hr></hr>

            <div class="citation">
            Johnson, Jessica Marie and Mark Anthony Neal. 2017. “Introduction: Wild Seed in the Machine.” The Black Scholar, 47 no. 3: 1-2.
            <br/><br/>
            </div>
            <div class="desc">
            This article positions Black Code Studies as a radical discipline that refutes conceptions of the digital world that seek to remove black diasporic people from interacting with and supporting the development of technology.
            </div>
            <hr></hr>

            <div class="citation">
            King, Timmia Jana. "Strategies and Approaches for Building an African American Digital Archive." PhD diss. Indiana University, 2020.
            <br/><br/>
            </div>
            <div class="desc">
            This work is foundational to understanding how archives, digital ethnography, work, and Blackness all intersect at the point of memory and preservation.
            </div>
            <hr></hr>

            <div class="citation">
            Everett, Anna. 2009. Digital Diaspora: A Race for Cyberspace. Albany, New York: State University of
            New York Press. 
            <br/><br/>
            </div>
            <div class="desc">
            This book traces the history of black participation in cyberspace by looking extensively at the early years of the internet. The author addresses problematic assumptions about black people as being outsiders in the information age and repositions them as eager users of technology and co-constituents in the revolution of information and technology.            
            </div>
            <hr></hr>

            <div class="citation">
            Faimau, Gabriel, and William O. Lesitaokana. 2018. New Media and the Mediatisation of Religion: An African Perspective. Newcastle upon Tyne: Cambridge Scholars Publishing.            
            <br/><br/>
            </div>
            <div class="desc">
            This book deals with the topic of digital and social media’s (new media) influence on cultural and religious practices with a specific influence on an Africa-centered perspective.             
            </div>
            <hr></hr>
            
            <div class="citation">
            Clay, Elonda. 2011. “Media, Spiritualities and Social Change.” In Mediated Science, Genetics and Identity in the US African Diaspora, edited by Stewart M. Hoover and Monica Emerich, 25-35. New York City: Continuum.
            <br/><br/>
            </div>
            <div class="desc">
            This book brings together perspectives from all over the world and different disciplines regarding the topic of spiritualized social change and how ‘spiritualities’ are expressed through media.
            </div>
            <hr></hr>

            <div class="citation">
            Clay, Elonda. 2011. “These Gods Got Swagger: Avatars, Gameplay, and the Digital Performance of Hip Hop Culture in Machinima.” Bulletin for the Study of Religion, no. 40: 4-9.
            <br/><br/>
            </div>
            <div class="desc">
            This article discusses the implication of game engines and movie-making software on African American religions by focusing on the aesthetic of ‘swagger’ in these contexts.
            </div>
            <hr></hr>

            <div class="citation">
            Clay, Elonda. 2015. “ #NOWTHATSRELIGIONANDHIPHOP: Mapping the Terrain of Religion and Hip Hop in Cyberspace.” In Religion in Hip Hop: Mapping the New Terrain in the US, edited by Miller, Monica R., Anthony B. Pinn, and Bernard ‘Bun B' Freeman, 87-95. New York City: Bloomsbury Publishing.
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Gault, Erika. 2022. Networking the Black Church: Digital Black Christians and Hip Hop. Forthcoming.
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Gault, Erika. 2018. “Performing to the Rhythm of Justice: Martin Luther King’s Speeches and the 
            Urban-Digital Context.” In Urban Ministry Reconsidered: Contexts and Approaches, edited by R. Drew Smith, Stephanie Boddie, and Ronald Peters, 247–53. Louisville, KY: Westminster John Knox Press.
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Gault, Erika. “Why Are All the Gospel Rappers Online? The Role of Social Media Sites 
            in Building Communities.” In Revisiting Subcultures: From Art of Resistance to Creative Innovation, edited by Steven Malliet, Alexander Dhoest, Jacques Haers, and Barbara Seagaert, 87-106, New York: Routledge.
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Gault, Erika, Guest Editor, Special Issue: Africana Digital Humanities, Fire!!!, “My People Are 
            Free: Theorizing the Digital Black Church” (Spring 2020).
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Gault, Erika. 2018. “When Saints Found Out: Tasha Cobbs, Nicki Minaj, and the Policing of 
            Black Christianity Online.” Fire!!! The Multimedia Studies Journal of Black Studies, no. 1: 9–34.
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Kiamu, Boye Nelson, and Bala A. Musa. 2021. "Theorizing the Communication of Digital Religion as Popular Culture in Africa: The Case for Alternative Epistemological Models." Howard Journal of Communications 32, no. 2: 139-155.
            <br/><br/>
            </div>
            <div class="desc">
            This article looks at digital religion in Africa as a mode of spiritual practice and popular culture, or entertainment, as an effort to create a mainstream culture.
            </div>
            <hr></hr>

            <div class="citation">
            Kiamu, Boye Nelson, and Bala A. Musa. 2021. "Theorizing the Communication of Digital Religion as Popular Culture in Africa: The Case for Alternative Epistemological Models." Howard Journal of Communications 32, no. 2: 139-155.
            <br/><br/>
            </div>
            <div class="desc">
            This article looks at digital religion in Africa as a mode of spiritual practice and popular culture, or entertainment, as an effort to create a mainstream culture.
            </div>
            <hr></hr>

            <div class="citation">
            Moultrie, Monique. 2017. Passionate and Pious: Religious Media and Black Women’s Sexuality. Durham, NC: Duke University Press. 
            <br/><br/>
            </div>
            <div class="desc">
            </div>
            <hr></hr>

            <div class="citation">
            Sampson, Melva L. 2020. "Digital Hush Harbors." Fire!!! 6, no. 1: 45-66.
            <br/><br/>
            </div>
            <div class="desc">
            This article challenges the reasons church environments are harmful to some members of the community and explores subversions, goals, and theories surrounding the spiritualities of the excluded. 
            </div>
            <hr class="final"></hr>
        </div>

        <h2 class="section">Other Resources and Helpful Links</h2>
        <div class="references">
            <a href="https://scholar.google.com/citations?user=Y34TFUEAAAAJ&hl=en">Professor Erika Gault's publications</a><br></br>
            <a href="https://www.colorado.edu/cmrc/resources/media-and-religion-bibliography">Center for Media, Religion and Culture: Media Religion Bibliography</a><br></br><br></br>
            <p>Network for New Media, Religion, and Digital Culture Studies<br></br>This website features an extensive bibliograhy of topics of Digital Religion that can be accessed below:</p>
            <a href="https://digitalreligion.tamu.edu/biblio">https://digitalreligion.tamu.edu/biblio</a><br></br>
            <a href="https://www.dslprojects.org/events">https://www.dslprojects.org/events</a><br></br>
            <a href="https://kaylareneewheeler.com/blackislamsyllabus/">https://kaylareneewheeler.com/blackislamsyllabus/</a>
        </div>

    </div>
  );
}

export default DigitalReligion;
