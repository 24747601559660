import React, { useState } from "react";
import Modal from "react-modal";
import "../../pages/Syllabus/authors.css";

Modal.setAppElement("#root");

const AuthorCircle = ({
  id,
  imageSrc,
  modalText,
  name,
  citations,
  connections,
  isActive,
  setActiveCircle,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleMouseEnter = () => {
    setActiveCircle(id);
  };

  const handleMouseLeave = () => {
    setActiveCircle(null);
  };

  return (
    <div
      className={`author-circle-container ${isActive ? "active" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div onClick={openModal}>
        <div
          className="author-circle"
          style={{ backgroundImage: `url(${imageSrc})` }}
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "1000",
          },
          content: {
            borderRadius: "10px",
            padding: "20px",
            width: "40%",
            height: "40%",
            margin: "auto",
          },
        }}
      >
        <div className="modal-header">
          <h2>{name}</h2>
          <button className="closeModal" onClick={closeModal}>
            X
          </button>
        </div>
        <div className="modal-content">
          <p>{modalText}</p>
        </div>
        <div className="modal-citations">
          <p>{citations}</p>
        </div>
      </Modal>
    </div>
  );
};

export default AuthorCircle;
