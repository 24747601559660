import React, { useState } from "react";
import { AboutSection } from "../../components/AboutSection/AboutSection";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import Slides from "./Slides.pdf";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import "./Survey.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function Survey() {
  const aboutSection = `Digital Black Religion Survey is an online survey and analysis of the digital religious practices and beliefs of Black people in the United States. The survey was created by social scientists and digital humanities researchers at the University of Arizona. Research consultants from diverse digital faith communities, museums, and organizations offered invaluable feedback on the survey design and instrumentation. An earlier version of the survey was administered to a small sample of 25 participants. The full online survey ran from November 2022-March 2023 and elicited 250 responses.`.split(
    "\n"
  );
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div className="surveyContainer">
      <div>
        <AboutSection
          text={aboutSection}
          title={"The Connected Faith Survey Page"}
          extra={
            <div>
              <br />
              <a href="https://www.dropbox.com/scl/fi/si1ew3q4t9lmc34gfte2k/Snapshots-Connected-Faith-Study-June-13-2023-Recording-1.pptx?cloud_editor=preview&dl=0&rlkey=u9bt3i4hj84d5vnn6fvz0ofdt">
                Download{" "}
              </a>
              the PowerPoint presentation
            </div>
          }
        />
      </div>
      <div className="Survey">
        <Document file={Slides} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} className="Survey-Page" />
          <div className="Buttons">
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              <FaArrowCircleLeft size={30} />
            </button>
            <p>
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </p>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              <FaArrowCircleRight size={30} />
            </button>
          </div>
        </Document>
      </div>
    </div>
  );
}

export default Survey;
