import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import "./authors.css";
import { AboutSection } from "../../components/AboutSection/AboutSection";
import AuthorCircle from "../../components/Syllabus/authorCircle";

import campbell from "./authorImages/campbell.png";
import dery from "./authorImages/dery.png";
import dawson from "./authorImages/dawson.png";
import moberg from "./authorImages/moberg.png";
import spadaro from "./authorImages/spadaro.png";
import wagner from "./authorImages/wagner.png";
import nelson from "./authorImages/nelson.png";
import bosch from "./authorImages/bosch.png";
import vitullo from "./authorImages/vitullo.png";
import cheong from "./authorImages/cheong.png";
import rouse from "./authorImages/rouse.png";
import boutros from "./authorImages/boutros.png";
import butler from "./authorImages/butler.png";
import helland from "./authorImages/helland.png";
import jackson from "./authorImages/jackson.png";
import clay from "./authorImages/clay.png";
import sampson from "./authorImages/sampson.png";
import gault from "./authorImages/gault.png";
import brock from "./authorImages/brock.png";
import fouche from "./authorImages/fouche.png";
import johnson from "./authorImages/johnson.png";
import everett from "./authorImages/everett.png";
import hoover from "./authorImages/hoover.png";
import lomax from "./authorImages/lomax.png";
import boyd from "./authorImages/boyd.png";

function Authors() {
  Modal.setAppElement("#root");

  const navigate = useNavigate();

  const authorsData = [
    {
      id: 0,
      name: "Heidi Campbell",
      imageSrc: campbell,
      modalText:
        "This book focuses on the five major world religions and approaches the topic from various perspectives such as - cell phones, video games and blogs. This book also deals with the topic of digital religion in a critical sense as it also considers the theoretical, ethical and theological implications of new media on religions.",
      citations:
        "Campbell, Heidi and Ruth Tsuria. 2012. Digital Religion: Understanding Religious Practice in New Media Worlds. New York City: Routledge.",
      connections: [3, 4, 11, 12, 13],
    },

    {
      id: 1,
      name: "Mark Dery",
      imageSrc: dery,
      modalText:
        "This book maps the impact of Cyberspace on the Gospel by looking at how faith is maintained in the age of the internet.",
      citations: "",
      connections: [],
    },

    {
      id: 2,
      name: "Lorne L. Dawson",
      imageSrc: dawson,
      modalText:
        "This book serves as an accessible introduction to the impact of the internet on religious topics ranging from the impact on the youth, the founding of new religious movements and also online recruitment.",
      citations:
        "Dawson, Lorne L. and Douglas E. Cowan. 2004. Religion Online: Finding Faith on the Internet. New York City: Routledge.",
      connections: [11],
    },

    {
      id: 3,
      name: "Marcus Moberg",
      imageSrc: moberg,
      modalText:
        "This book deals with the growing impact of social media on young adults in seven different countries and explores how young people use social media in religiously significant ways. The book has several case studies to illustrate how social media is used differently amongst young people across the world.",
      citations:
        "Moberg, Marcus and Sofia Sjö. 2020. Digital Media, Young Adults and Religion: An International Perspective. New York City: Routledge.",
      connections: [0],
    },

    {
      id: 4,
      name: "Antonio Spadaro",
      imageSrc: spadaro,
      modalText:
        "This book addresses the influence of the internet on Christianity from the Catholic perspective and looks at the processes of how theology changes with its interaction with the internet.",
      citations:
        "Spadaro, Antonio. 2014. Cybertheology: Thinking Christianity in the Era of the Internet. New York City: Fordham University Press.",
      connections: [0, 7],
    },

    {
      id: 5,
      name: "Rachel Wagner",
      imageSrc: wagner,
      modalText:
        "This book explores what religious practice in the digital age looks like and looks at a variety of virtual activities that contribute to meaning making in our religious practices.",
      citations:
        "Wagner, Rachel. 2012.  Godwired: Religion, Ritual and Virtual Reality. New York City: Routledge.",
      connections: [11],
    },

    {
      id: 6,
      name: "Alondra Nelson",
      imageSrc: nelson,
      modalText: "",
      citations: "",
      connections: [],
    },

    {
      id: 7,
      name: "Miriam Diez Bosch",
      imageSrc: bosch,
      modalText:
        "This article focuses on Catalan teenagers’ usage of technology in their religious practice. The context might not fit the topic of black religion, but I think that a similar type of study in an American context could yield interesting results.",
      citations:
        "Bosch, Miriam Diez, Sanz Josep Lluís Micó Sanz, and Alba Sabaté Gauxachs. 2017.“Typing My Religion: Digital Use of Religious Webs and Apps by Adolescents and Youth for Religious and Interreligious Dialogue.” Church, Communication and Culture, 2 no. 2: 121–143.",
      connections: [4],
    },

    {
      id: 8,
      name: "Alessandra Vitullo",
      imageSrc: vitullo,
      modalText:
        "This article focuses on research studies within the field of Digital religion and what the approach was when looking at the religious communities in question.",
      citations:
        "Campbell, Heidi and Alessandra Vitullo. 2016. “Assessing Changes in the Study of Religious Communities in Digital Religion Studies.” Church, Communication and Culture, 1 no. 1: 73-89.",
      connections: [9],
    },

    {
      id: 9,
      name: "Pauline H. Cheong",
      imageSrc: cheong,
      modalText:
        "This article looks at the influence of ‘tweets’ on religion and how they contribute to the reconstruction of religious authority and the perception of sacred texts.",
      citations:
        "Cheong, Pauline H. 2015. Tweet the Message? Religious Authority and Social Media Innovation. The Journal of Religion, Media and Digital Culture 3 no. 3: 1–19.",
      connections: [8],
    },

    {
      id: 10,
      name: "Moxley Rouse",
      imageSrc: rouse,
      modalText:
        "This book mainly deals with the topic of how Black Christians, Muslims, and Jews have used media to prove their equality, not only in the eyes of God, but also in society.",
      citations:
        "Moxley Rouse, Carolyn, John L. Jackson, Marla F. Frederick. 2016. Televised Redemption: Black Religious Media and Racial Empowerment. NYU Press.",
      connections: [14],
    },

    {
      id: 11,
      name: "Alexandra Boutros",
      imageSrc: boutros,
      modalText:
        "This article looks at digital representation of the Black Diaspora in blogs and argues that these instances of online visibility are valuable to the digital counterpublic discourse.",
      citations:
        'Stolow, Jeremy, and Alexandra Boutros. "Visible/Invisible: Religion, Media, and the Public Sphere." Canadian Journal of Communication 40, no. 1 (2015): 3-10.',
      connections: [0, 2, 5, 18, 22, 23],
    },

    {
      id: 12,
      name: "Philip Butler",
      imageSrc: butler,
      modalText:
        "Mediating Black religious studies, spirituality studies, and liberation theology, Philip Butler explores what might happen if Black people in the United States merged technology and spirituality in their fight towards materializing liberating realities.",
      citations:
        "Butler, Phillip. 2019. Black Transhuman Liberation Theology: Technology and Spirituality. NY: Bloomsbury.",
      connections: [0],
    },

    {
      id: 13,
      name: "Christopher Helland",
      imageSrc: helland,
      modalText:
        "This study demonstrates how diaspora religious traditions utilized the Internet to develop significant network connections among each other and also to their place of origins.",
      citations:
        'Helland, Christopher. 2007. "Diaspora on the Electronic Frontier: Developing Virtual Connections with Sacred Homelands." Journal of Computer-Mediated Communication 12, no. 3: 956-976.',
      connections: [0],
    },

    {
      id: 14,
      name: "John L. Jackson, Jr.",
      imageSrc: jackson,
      modalText:
        "This article argues that ethnography needs to be redefined when digitality is taken seriously in a “post-Writing Culture moment” as sociality changes with technology and ethnography grows with it.",
      citations:
        "Jackson Jr., John L. 2012. “ETHNOGRAPHY IS, ETHNOGRAPHY AIN’T.” Cultural Anthropology, no. 27: 480-497. https://doi.org/10.1111/j.1548-1360.2012.01155.x",
      connections: [22, 14],
    },

    {
      id: 15,
      name: "Elonda Clay",
      imageSrc: clay,
      modalText:
        "This essay discusses the impact of technological trends on the Black Church and calls for a shared responsibility with Christians and private organizations that provide the technology.",
      citations:
        'Clay, Elonda. "#NOWTHATSRELIGIONANDHIPHOP: Mapping the Terrain of Religion and Hip Hop in Cyberspace." Religion in Hip Hop 2015: 87-95.',
      connections: [0, 19],
    },

    {
      id: 16,
      name: "Melva Sampson",
      imageSrc: sampson,
      modalText:
        "This article challenges the reasons church environments are harmful to some members of the community and explores subversions, goals, and theories surrounding the spiritualities of the excluded.",
      citations:
        " Sampson, Melva L. “Digital Hush Harbors: Black Preaching Women and Black Digital Religious Networks.” Fire!!! 6, no. 1 (2020): 45–66. https://www.jstor.org/stable/10.5323/48581553.",
      connections: [17, 21],
    },

    {
      id: 17,
      name: "Erika Gault",
      imageSrc: gault,
      modalText:
        "This article argues that ethnography needs to be redefined when digitality is taken seriously in a “post-Writing Culture moment” as sociality changes with technology and ethnography grows with it.",
      citations:
        'Gault, Erika. 2018. "Performing to the Rhythm of Justice: Martin Luther King’s Speeches and the Urban-Digital Context.” In Urban Ministry Reconsidered: Contexts and Approaches, edited by R. Drew Smith, Stephanie Boddie, and Ronald Peters, 247–53. Louisville, KY: Westminster John Knox Press.',
      connections: [0, 1, 9, 15, 16, 18, 21],
    },

    {
      id: 18,
      name: "Andre Brock",
      imageSrc: brock,
      modalText:
        "This work places Blackness at the forefront of internet culture and examines the performative aspects of African American Identity in a variety of platforms and practices.",
      citations:
        "Brock, Jr., André. Distributed Blackness: African American Cybercultures, New York, USA: New York University Press, 2020.",
      connections: [11, 22, 24],
    },

    {
      id: 19,
      name: "Rayvon Fouche",
      imageSrc: fouche,
      modalText:
        "This article discusses how African Americans should reevaluate their relationship with technology and critically engage with how Blackness is represented through technology.",
      citations:
        "Fouche, Rayvon. 2006. “Say It Loud, I’m Black and I’m Proud: African Americans, American Artifactual Culture, and Black Vernacular Technological Creativity.” American Quarterly 58: 639-661. https://www.jstor.org/stable/40068387",
      connections: [15, 18],
    },

    {
      id: 20,
      name: "Jessica Marie Johnson",
      imageSrc: johnson,
      modalText:
        "Jessica Marie Johnson & Mark Anthony Neal (2017) Introduction: Wild Seed in the Machine, The Black Scholar, 47:3,1-2.",
      citations:
        "This article positions Black Code Studies as a radical discipline that refutes conceptions of the digital world that seek to remove black diasporic people from interacting with and supporting the development of technology.",
      connections: [1, 6, 18],
    },

    {
      id: 21,
      name: "Tamura Lomax",
      imageSrc: lomax,
      modalText:
        "This work is foundational to understanding how archives, digital ethnography, work, and Blackness all intersect at the point of memory and preservation.",
      citations:
        "Jackson Jr., John L. 2012. “ETHNOGRAPHY IS, ETHNOGRAPHY AIN’T.” Cultural Anthropology, no. 27: 480-497. https://doi.org/10.1111/j.1548-1360.2012.01155.x",
      connections: [],
    },

    {
      id: 22,
      name: "Anna Everett",
      imageSrc: everett,
      modalText:
        "This book traces the history of black participation in cyberspace by looking extensively at the early years of the internet. The author addresses problematic assumptions about black people as being outsiders in the information age and repositions them as eager users of technology and co-constituents in the revolution of information and technology.",
      citations:
        "Everett, Anna. 2009. Digital Diaspora: A Race for Cyberspace. Albany, New York: State University of New York Press.",
      connections: [11, 14],
    },

    {
      id: 23,
      name: "Stewart Hoover",
      imageSrc: hoover,
      modalText:
        "This book deals with the topic of digital and social media’s (new media) influence on cultural and religious practices with a specific influence on an Africa-centered perspective.",
      citations:
        "Jackson Jr., John L. 2012. “ETHNOGRAPHY IS, ETHNOGRAPHY AIN’T.” Cultural Anthropology, no. 27: 480-497. https://doi.org/10.1111/j.1548-1360.2012.01155.x",
      connections: [],
    },

    {
      id: 24,
      name: "Danah Boyd",
      imageSrc: boyd,
      modalText: "",
      citations: "",
      connections: [],
    },
  ];

  const aboutText = `Origin stories are important. They tell us how a field began, the influencers that changed its trajectory, and its possibility for future impact. The study of Digital Black Religion is no different. The following visualization seeks to model the scholarly genealogy and networks of Digital Black Religion. Hover over each image to learn which scholars, text, ideas, and/or concepts influenced its development. For a fuller portrait of the scholarly interventions in Digital Black Religion check out the bibliography.`.split(
    "\n"
  );

  const [activeCircle, setActiveCircle] = useState(null);

  return (
    <div className="digitalReligionContainer">
      <AboutSection text={aboutText} title="The Research Hub Page" />
      <div className="author-list">
        {authorsData.map((author) => (
          <div
            className="author-circle-container"
            style={{ alignItems: "center" }}
          >
            <AuthorCircle
              key={author.id}
              {...author}
              isActive={
                activeCircle === author.id ||
                author.connections.includes(activeCircle)
              }
              setActiveCircle={setActiveCircle}
            />
            <p className="authorName">{author.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Authors;
