import React from "react";
import "./AboutSection.css";

export function AboutSection({ title, text, extra }) {
  return (
    <div className="aboutSection">
      <div className="aboutSectionTitle">{title}</div>
      <div className="aboutSectionText">
        {text.map((line, i) => (
          <span key={i}>
            {line}
            <br />
          </span>
        ))}
        {extra}
      </div>
    </div>
  );
}
