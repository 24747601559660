import React from "react";
import "./About.css";

function About() {
  const bg4 = "#f5f9f2";
  const bg2 = "#d5e6ca";
  const bg3 = "#e0edd7";
  const bg1 = "#eaf3e4";

  return (
    <div style={{ width: "80vw", margin: "20px auto" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          margin: "0px",
        }}
      >
        <div className="about-aboutSection" style={{ backgroundColor: bg1 }}>
          <div className="about-aboutSectionTitle">About</div>
          <div className="about-aboutSectionText">
            At the intersection of faith and technology we find abolitionists
            like Tubman, Nat Turner, and many others manipulating the
            technologies of their day in service to Black freedom. Digital Black
            scholars argue that such strategies—that is, the emancipatory
            communication networks created by Tubman through the use of the
            spirituals, the Underground Railroad, and even prayer and
            prophecy—are technologies in themselves. These networks demonstrate
            that Black religion has always been rooted in technological as well
            as spiritual practice
            <br />
            <br />
            Echoes of the networks employed by Tubman and other Black
            abolitionists remain embedded in the digital practices of modern
            Black religious people today. From Black Christian women’s
            reinvention of the notion of “the Black Church” through their
            formation of podcast-, social media-, and streaming-oriented
            “worship centers” to Black millennials being described as online
            “viral vanguards” in a recent Nielsen Report to the Yoruban
            practices of Ifá and other such traditions that undergird
            hashtag-driven movements such as #BLM, digital Black religion is a
            current running through much of the literature, both scholarly and
            popular on emerging digital technology.
          </div>
        </div>
        <div>
          <div className="about-aboutSection" style={{ backgroundColor: bg2 }}>
            <div className="about-aboutSectionText">
              <div className="about-aboutSectionTitle">Elements</div>
              This platform takes an incisive look at the history and present
              contours of digital Black religion through three components:
              <br />
              <br />
              <div style={{ fontWeight: "bold" }}>
                The Timeline of Black Twitter Faith
              </div>
              The Timeline of Black Twitter Faith looks at the history of Black
              religion since Twitters inception through key topics popular in
              Black Twitter discourse.
              <br />
              <br />
              <div style={{ fontWeight: "bold" }}>Connected Faith Survey</div>
              The Connected Faith Survey provides an intimate portrait of Black
              American religious beliefs and practices around digital technology
              through a survey and interviews.
              <br />
              <br />
              <div style={{ fontWeight: "bold" }}> The Research Hub</div>
              The Research Hub visualizes the network of scholarly work in
              digital Black religion and provides a comprehensive bibliography
              of scholarly interventions on the topic.
            </div>
          </div>
          <div className="about-aboutSection" style={{ backgroundColor: bg3 }}>
            <div className="about-aboutSectionText">
              <div className="about-aboutSectionTitle">Credits</div>
              This website was developed by the University of Arizona Center for
              Digital Humanities by developers Hamad Marhoon and Joanna
              Zabasajja
            </div>
          </div>
        </div>
      </div>
      <div
        className="about-aboutSectionBottom"
        style={{ backgroundColor: bg4 }}
      >
        <div className="about-aboutSectionText">
          <div className="about-aboutSectionTitle">
            A Timeline of the Platforms Development
          </div>
          <div style={{ fontWeight: "bold" }}>2013</div>
          Black digital religion scholar Erika Gault was wrapping up her
          dissertation on the religious lives of gospel rappers when a committee
          member, Dr. Jason Young, suggested to her that her study of research
          participants' online religious lives was "a real thing" and she should
          explore this further as a book project. That work became Networking
          the Black Church: Digital Black Religion and Hip Hop (2022).
          <br />
          <br />
          <div style={{ fontWeight: "bold" }}>2013-2020</div> An explosion of
          Black digital religious communities like Danyelle Thomas' Unfit
          Christian and Melva Sampson’s Pink Robe Chronicle took place. These
          communities attracted large numbers of Black adherents. When the
          pandemic began, they continued to provide guidance to their followers
          and an increasing number of new followers displaced by the pandemic
          from their physically located religious communities. Digital faith
          leaders more generally experienced increased engagement with their
          digital content. The role of such digital Black communities and faith
          leaders in the physical to digital migration that occurred in Black
          faith communities during this time had yet to be fully captured in the
          literature.
          <br />
          <br />
          <div style={{ fontWeight: "bold" }}>June 2020</div> At the height of
          the pandemic and in wake of the tragic murders of Black men and women
          like George Floyd, Ahmaud Arbery, and Breonna Taylor and subsequent
          protests a digital event was held to discuss what this all meant and
          what role Black religion might play in mobilizing the prophetic voices
          among us. That event, titled "Gathering in the Moment: Mobilizing the
          Digital Black Church's Prophetic Witness" was hosted by Dr. Erika
          Gault and sponsored by the{" "}
          <a href="https://digitalhumanities.arizona.edu" target="_blank">
            {" "}
            Center for Digital Humanities
          </a>{" "}
          (Dr. Bryan Carter) and the{" "}
          <a href="https://fteleaders.org" target="_blank">
            {" "}
            Forum for Theological Exploration
          </a>{" "}
          (Dr. Darlene Hutto). It drew over 11,000 viewers and sparked a
          conversation among participants regarding next steps for the study of
          digital Black religion.
          <br />
          <br />
          <div style={{ fontWeight: "bold" }}>2021-2023</div> Louisville
          Institute provided funding through the Project Grant for Researchers
          to Associate Professor and Principal Investigator Dr. Erika Gault to
          lead a national study of the digital religious beliefs and practices
          of Black Americans. NEH Advancing Digital Humanities Initiative
          provided another $50,000 to develop a platform and bring scholars and
          practitioners together to create tools and guidance regarding the
          platform’s development.
        </div>
      </div>
    </div>
  );
}

export default About;
