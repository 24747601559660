import React, { useState } from "react";
import "./Twitter.css";
import TweetCard from "../../components/Twitter/TweetCard";
import Modal from "react-modal";
import { AboutSection } from "../../components/AboutSection/AboutSection";

import DMX from "../../images/dmxwordcloud.png";
import GetOut from "../../images/getoutwordcloud.png";
import Lemonade from "../../images/lemonadewordcloud.png";
import BlackPanther from "../../images/blackpantherwordcloud.png";
import TwitterWhite from "../../images/twitter-white-bg.png";
import dmxIcon from "../../images/dmxIcon.jpeg";
import lemonadeIcon from "../../images/lemonadeIcon.jpeg";
import getoutIcon from "../../images/getoutIcon.jpg";
import wakandaIcon from "../../images/wakandaIcon.jpeg";
import millionWordcloud from "../../images/millionwordcloud.png";
import millionIcon from "../../images/millionIcon.jpg";
import floydWorldcloud from "../../images/floydwordcloud.png";
import floydIcon from "../../images/floydIcon.jpg";
import nipseyIcon from "../../images/nipseyIcon.jpg";
import nipseyWordcloud from "../../images/nipseywordcloud.png";
import graceWordcloud from "../../images/amazinggracewordcloud.png";
import graceIcon from "../../images/graceIcon.jpg";

function Twitter() {
  Modal.setAppElement("#root"); // Replace '#root' with your app's root element id

  const aboutText = `Black Twitter as an essential space for Black discourse, activism, and cultural production has been well documented (Brock 2012). Black religion has long held the same sort of socialites exhibited in online practices. This timeline looks at the history of Black religion since Twitters inception through key topics popular in Black Twitter discourse. Beginning with Twitters inception and concluding with the buyout of the social media platform by Elon Musk on October 27, 2022. We conclude with this date given Black practices around obituaries. In the final days of its existence Black content creators filled the Twittersphere with obits which exhibited the sort of playmaking, joy, and meaningful critique of the digital that has served as an important aspect of the social platform. It also marked a shift in the design and structure of the platform and thus the history of digital Black religion.
  
  The data was derived from scraping Tweets per topic. About 1000 tweets were scraped for each topic that is here, then word frequency was analyzed and visualized using the wordclouds. A bigger font for a word in the wordcloud infers a higher frequency of the occurance of that word.`.split(
    "\n"
  );

  const tweetData = [
    {
      profileImage: floydIcon,
      image: floydWorldcloud,
      handle: "@BLM",
      username: "George Floyd Protests",
      content: `The digital documentation and routinization of such crimes against Black life became common over the last two decades. The gruesome nature of Eric Chauvin murdering George Floyd vwas uncommon in its gruesome...`,
      timestamp: "May 26, 2020",
      description: `The digital documentation and routinization of such crimes against Black life became common over the last two decades. The gruesome nature of Eric Chauvin murdering George Floyd vwas uncommon in its gruesome and egregious display, leading to several protests.  
For just as long, there have been state sanctioned forms of violence against Black life, Black meditations on death and appeals for justice have also been raised. Protests and post in support of the same included several elements of black spiritual practices around death and dying such as memorializing the dead through libation, prayers, and performances of death such as times of silence for 8:46. Note how “race,” “Chauvin,” and “Floyd” (or “Muerte” or “knee”) appear in relation to secondary words or larger calls for “justice.” `,
    },
    {
      profileImage: wakandaIcon,
      username: "Wakanda",
      handle: "@Wakanda",
      content:
        "As with the first Black Panther movie, Black Panther II relied heavily on faith practices of indigenous communities. Moving beyond Africa, to embrace Meso-American traditions, II envisioned a future in...",
      timestamp: "Nov 11, 2022",
      description:
        "As with the first Black Panther movie, Black Panther II relied heavily on faith practices of indigenous communities. Moving beyond Africa, to embrace Meso-American traditions, II envisioned a future in which Black and Brown people infused spiritual practice with claims to national sovereignty. To date his is the largest budget film that prominently featuring non-Judea Christian religious practices. On the tertiary word level, audiences noted the unique shift in racial religious discourse in Marvel movies using words like “indigenous,” “Latinos,” “brown,” and “brown.”  ",
      image: BlackPanther,
    },
    {
      profileImage: graceIcon,
      image: graceWordcloud,
      username: "Amazing Grace",
      handle: "@AmazingGrace",
      content: "",
      description: "",
      timestamp: "June 26, 2015",
    },
    {
      profileImage: nipseyIcon,
      username: "Nipsey Hussle",
      handle: "@HussleNMotivate",
      content: "",
      timestamp: "Mar 31, 2019",
      description: "",
      image: nipseyWordcloud,
    },
    {
      profileImage: dmxIcon,
      username: "DMX",
      handle: "@DMX",
      content: "",
      timestamp: "April 2, 2021",
      description: "",
      image: DMX,
    },
    {
      profileImage: getoutIcon,
      username: "Get Out",
      handle: "@JordanPeele",
      content: "",
      timestamp: "Feb 24, 2017",
      description: "",
      image: GetOut,
    },
    {
      profileImage: millionIcon,
      username: "Million Man 20th Anniversary",
      handle: "@MillionMan",
      content: "",
      description: "",
      image: millionWordcloud,
      timestamp: "Oct 10, 2015",
    },
    {
      profileImage: lemonadeIcon,
      username: "Candice Benbow",
      handle: "@CandiceBenbow",
      content: "",
      timestamp: "May 7, 2016",
      description: "",
      image: Lemonade,
    },
  ];

  return (
    <div className="twitterContainer">
      <div style={{ marginBottom: "15vh" }}>
        <AboutSection
          title={"The Timeline of Black Twitter Faith"}
          text={aboutText}
        />
      </div>

      <div className="twitterLogo" />
      {tweetData.map((tweet, index) => (
        <div
          key={index}
          className={index % 2 === 0 ? "leftTweet" : "rightTweet"}
        >
          <TweetCard {...tweet} />
        </div>
      ))}
      <div className="twitterLine" />
    </div>
  );
}

export default Twitter;
