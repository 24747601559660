import React, { useState } from "react";
import Modal from "react-modal";
import "../../pages/Twitter/Twitter.css";

Modal.setAppElement("#root");

const TweetCard = ({
  profileImage,
  username,
  handle,
  content,
  timestamp,
  image,
  description,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div className="tweet-card">
      <div className="tweet-header">
        <img src={profileImage} alt="Profile" className="profile-image" />
        <div className="tweet-user-info">
          <span className="username"> {username}</span>
          <span className="handle"> {handle}</span>
          <span className="timestamp"> · {timestamp}</span>
        </div>
      </div>
      <div className="tweet-body">
        <div className="tweet-content">
          <p>{content}</p>
          {description.length < 1 ? (
            <div>
              <p>
                {
                  "Further website developments will include descriptive text from the public. Please check back soon for more regarding how you can participate in this project."
                }
              </p>
            </div>
          ) : (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setModalVisible(true);
              }}
              style={{ color: "blue" }}
            >
              Read more
            </a>
          )}
        </div>

        <div className="tweet-image">
          <img src={image} alt="Tweet" />
        </div>
      </div>

      {/* Modal */}

      <Modal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        contentLabel="Read more"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "1000",
          },
          content: {
            borderRadius: "10px",
            padding: "20px",
            width: "40%",
            height: "60%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
      >
        <img
          src={profileImage}
          alt="FullImage"
          style={{
            maxWidth: "100%",
            maxHeight: "40%",
            margin: "0 auto",
            objectFit: "contain",
          }}
        />
        <p className="descriptionText">{description}</p>
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <button
            onClick={() => setModalVisible(false)}
            style={{ color: "blue" }}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default TweetCard;
