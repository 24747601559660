import React from "react";
import "./Homepage.css";
import centerImage from "../../images/centreImage.png";
import rightImage from "../../images/twitter.png";
import leftImage from "../../images/connected_faith.png";
import { useNavigate } from "react-router-dom";

function Homepage() {
  const navigate = useNavigate();

  return (
    <div className="homepageContainer">
      <div className="circles">
        <div className="circleContainer" id="homepageLeftCircle">
          <img
            src={leftImage}
            className="circleImage"
            alt="Left"
            onClick={() => {
              navigate("/Survey");
            }}
          />
          <p className="circleText">Connected Faith Survey Page</p>
        </div>

        <div className="circleContainer" id="homepageCenterCircle">
          <img
            src={centerImage}
            className="circleImage"
            alt="Center"
            onClick={() => {
              navigate("/Authors");
            }}
          />
          <p className="circleText">The Research Hub Page</p>
        </div>

        <div className="circleContainer" id="homepageRightCircle">
          <img
            src={rightImage}
            className="circleImage"
            alt="Right"
            onClick={() => {
              navigate("/Twitter");
            }}
          />
          <p className="circleText">The Timeline of Black Twitter Faith</p>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
